@import "common.css";

.unit-view {
	padding-top: 155px;

	@media (max-width: 991px) {
		padding-top: 121px;
	}

	@media (max-width: 596px) {
		padding-top: 145px;
	}

	.price {
		font-weight: bold;
	}

	.flow-buttons {
		.btn {
			padding: 10px 18px;

			&.btn-go {
				margin-left: 15px;
				margin-bottom: 20px;
			}
		}
	}

	.text-container {
		position: relative;
		max-height: 17em;
		overflow: hidden;
		transition: max-height 0.5s ease;
		scroll-margin-top: 200px;
	}

	.text-container.expanded {
		max-height: none;
	}

	.text-fade {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 5em;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0), white);
		pointer-events: none;
	}



	.video-row {
		margin: 0 -10px;

		.videoWrapper {
			iframe {
				width: 100%;
				height: 450px;

				@media (max-width:1199px) {
					height: 300px;
				}
			}
		}
	}

	.imgcols-half {
		width: 50%;
		padding: 0 10px;
		float: left;
		margin-bottom: 20px;

		@media (max-width:640px) {
			width: 100%;
		}

		.videoWrapper {
			position: relative;
			padding-bottom: 63%;
			/* 16:9 */
			height: 0;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	.calendartext {
		position: absolute;
		width: 100%;
		top: -18px;

		p {
			line-height: 1.2;
			font-weight: bold;

			@media (max-width:767px) {
				font-size: 12px !important;
			}
		}
	}

	.subrow {
		.book-btn {
			&.btn-lg {
				height: 40px;
				font-size: 16px;
			}
		}
	}

	.contact-form-popup {
		border: none;
		padding: 0;

		.row {
			flex-wrap: wrap !important;
		}

		.btn.btn-go {
			width: auto;
		}
	}

	.Anfragen-Popup {
		.dlg.show {
			display: flex !important;
			align-items: center;
		}

		.header {
			h4 {
				margin: 10px 0;
			}
		}

		.header-close {
			position: absolute;
			top: 0;
			right: 15px;
			top: 16px;
			width: 20px;
			height: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #3c3c3c;
			color: #3c3c3c;
			font-size: 14px;
			border-radius: 100px;
			cursor: pointer;
			opacity: 0.7;

			&:hover {
				opacity: 1;
			}
		}

		.box {
			height: 520px;

			@media (max-width: 767px) {
				height: 100%;
			}

			.body {
				padding: 30px 20px 0 20px;
			}
		}
	}

	.unit-btm-bl {
		right: 30px;
		top: 33px;
		z-index: 2;
		position: absolute;

		@media (max-width:992px) {
			right: 10px;
			top: 24px;
		}

		>span {
			line-height: 2;
			display: inline-block;
			margin-right: 5px;
			padding: 0 10px;
			font-size: 14px;
			color: var(--white);

			&.bg-green {
				background-color: var(--secondary);
			}

			&.bg-blue {
				background-color: var(--primary);
			}
		}
	}

	.price-table {
		margin-top: 20px;

		td:first-child {
			width: 55%;
		}

		td:last-child {
			text-align: right;
		}
	}

	.title-img-container {
		width: 100%;
		cursor: pointer;
	}


	.search-section {
		.searchbox {
			margin: 1px;
		}

		.search-popup-trigger {
			cursor: pointer;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: white;
		}

	}

	.search-bl {
		position: relative;

		i {
			background-color: var(--green);
			width: 34px;
			border-radius: 0 5px 5px 0;
			color: #c3c6cc;
			position: absolute;
			right: 0;
			text-align: center;
			height: 34px;
			top: 0;
			line-height: 34px;
			pointer-events: none;
		}

		.datepicker-trigger {
			display: block;
		}

		.form-control {
			padding: 7px 15px;
			border: 1px solid #c3c6cc;
			border-radius: 4px;
			height: 34px;
			margin-bottom: 15px;
		}
	}

	.unit-icons {
		span {
			background-image: url(RESOURCE/img/unit-icons.png);
			width: 36px;
			height: 36px;
			display: inline-block;
			border-radius: 50%;
			margin-right: 8px;
			background-repeat: no-repeat;
			display: inline-block;
			background-color: #9D9D9D;
			margin-bottom: 5px;

			&.pets-cross {
				background-position: -253px -1px;
			}

			&.smoke {
				background-position: -304px -1px;
			}

			&.balcony {
				background-position: -122px -1px;
			}

			&.washing {
				background-position: -76px -1px;
			}

			&.pool {
				background-position: 0 0;
			}

			&.wlan {
				background-position: -24px 3px;
				background-size: 405px;
			}

			&.spulmachine {
				background-position: -350px 0px;
			}

			&.oven {
				background-position: 8px -2px;
			}

			&.shower {
				background-position: -192px -5px;
				background-size: 565px;
			}

			&.bathtub {
				background-position: 0 0;
			}

			&.pets {
				background-image: url(RESOURCE/img/unit-icons2-new.png);
				background-position: -44px 2px;
			}

			&.sauna {
				background-image: url(RESOURCE/img/unit-icons2-new.png);
				background-position: -133px 3px;
			}

			&.terrace {
				background-image: url(RESOURCE/img/unit-icons2-new.png);
				background-position: -89px 1px;
			}

			&.swimming {
				background-image: url(RESOURCE/img/unit-icons2-new.png);
				background-position: 3px 0px;
			}

			&.beachchair {
				background-position: -398px -1px;
			}

			&.seeview {
				background-position: -456px 0px;
			}
		}
	}

	.unit-list-section {
		.owl-item {
			position: relative;
			border-left: 5px solid var(--white);
			border-right: 5px solid var(--white);

			&:before {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(255, 255, 255, 0.6);
				content: ' ';
				transition: all 0.5s ease;
				z-index: 1;
			}

			&:hover {
				&:before {
					background: rgba(255, 255, 255, 0.8);
				}
			}

			&.active {
				&:before {
					background-color: rgba(255, 255, 255, 0);
				}
			}
		}

		.owl-nav {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			max-width: 1380px;
			margin: 0 auto;
			transform: translate(-50%, 0);

			@media (max-width:992px) {
				display: none;
			}


			button {
				width: 60px;
				height: 60px;
				background-color: var(--black2) !important;
				position: absolute;
				left: 50px;
				font-size: 34px;

				span {
					display: inline-block;
					font-style: normal;
					font-variant: normal;
					text-rendering: auto;
					line-height: 1;
					color: transparent;

					&:before {
						color: var(--white);
						font-family: fontawesome;
						position: relative;
						left: 5px;
						top: 0px;
						font-size: 24px;
					}
				}

				&:hover {
					background-color: var(--primary) !important;
				}

				&.owl-next {
					position: absolute;
					right: 50px;
					left: initial;

					span {

						&:before {
							content: "\f054";
							left: 5px;
						}
					}
				}

				&.owl-prev {
					span {

						&:before {
							content: "\f053";

						}
					}
				}
			}
		}

		.item {
			overflow: hidden;
			width: 100%;
			padding-bottom: 56.25%;

			@media (max-width:992px) {
				height: 250px;
			}


			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
			}
		}
	}

	.title-img {

		position: relative;
		width: 100%;
		height: 450px;

		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;


		.v-left-gallery-btn,
		.v-right-gallery-btn {
			font-size: 160px;
			position: absolute;
			top: 50%;
			margin-top: -57px;
		}

		.v-left-gallery-btn:hover,
		.v-right-gallery-btn:hover {
			color: #585858;
		}

		.v-right-gallery-btn {
			right: 40px;
		}

		.v-left-gallery-btn {
			left: 40px;
		}

	}



	.panorama {
		position: relative;
		width: 100%;
		height: 700px;
	}



	.main-section {
		position: relative;
		padding: 0;

		.breadcrumb {
			padding-left: 0;
		}

		.page-header {
			margin-top: 20px;
			border-bottom: none;
			margin-bottom: 0;
			position: relative;

			.rating-unit {
				color: #FECA02;
			}

			.btn-picture {
				position: absolute;
				left: 0;
				top: -50px;
				z-index: 10;
				font-size: 14px;
				padding-left: 35px;
				text-transform: inherit;

				i {
					font-size: 18px;
					position: absolute;
					top: 5px;
					margin-right: 6px;
					left: 10px;
				}
			}

			.unit-title-bx {
				margin-top: 25px;
				margin-bottom: 15px;
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				@media (max-width:767px) {
					display: block;
					overflow: hidden;
				}


				h1 {
					margin-right: 40px;
					margin: 5px 40px 5px 0;
				}

				.btn {
					text-transform: inherit;

					@media (max-width:767px) {
						float: right;
					}
				}
			}

			@media (max-width:992px) {
				margin-top: 10px;
			}


			.unit-dt-row {
				display: flex;
				justify-content: space-between;

				.back-btn {
					color: var(--primary);

					.fal {
						margin-right: 5px;
					}
				}

				.unit-dt-bl {
					margin: 0 0 5px;

					@media (max-width:992px) {
						margin-top: 0;
					}

					li {
						font-family: var(--font-heading);
						padding-right: 15px;
						font-size: 18px;
						display: inline-block;
						color: var(--green);

						i {
							margin-right: 3px;

							&.icon {
								background-image: url(RESOURCE/img/sprite-icon-units3.png);
								width: 25px;
								height: 25px;
								display: inline-block;
								background-repeat: no-repeat;
								position: relative;
								top: 5px;

								&.icon-laundry {
									background-position: -5px -1px;
								}

								&.icon-pets {
									background-position: -35px -2px;
								}

								&.icon-no-smoking {
									background-position: -65px -2px;
								}

								&.icon-no-pets {
									background-position: -95px -2px;
								}

								&.icon-smoking {
									background-position: -125px -2px;
								}
							}
						}


						@media (max-width:992px) {
							padding: 0 7px;
						}

						&:first-child {
							padding-left: 0;
							border-left: none;
						}
					}
				}
			}




		}


		.unit-grey-section {

			.row {
				@media (min-width:993px) {
					display: flex;
				}
			}



			.unit-obt {
				font-size: 14px;
				display: inline-block;
				padding: 5px 10px;
				background-color: var(--primary);
				border-radius: 5px;
				color: var(--white);
				font-weight: bold;
				letter-spacing: .5px;
				line-height: 1.5;
			}

		}

		.unit-prop-bl {


			strong {
				color: var(--green);
				font-size: 16px;
			}

			p {
				font-size: 16px;
			}
		}

		h2 {
			margin-top: 40px;
			font-size: 26px;

			.stars {
				display: inline-block;
				margin-left: 22px;
				color: #dfb62d;
			}
		}

		.col-md-3.bg-white {
			@media (max-width:992px) {
				padding-top: 15px;
			}
		}

		.contact-form {
			padding: 10px;
			border: 1px solid var(--grey);

			h2 {
				margin-top: 10px;
			}

			.form-control {
				padding-left: 10px;
			}
		}

		.col-lg-3 {

			.unit-side-price {
				display: none;

				@media (min-width:993px) {
					display: block;
				}
			}

			.contact-form {
				display: none;

				@media (min-width:993px) {
					display: block;
				}
			}
		}

		.unit-side-price {
			padding: 30px 15px 15px;
			color: var(--white);
			min-height: 166px;
			background-color: var(--black2);
			text-align: center;
			border-radius: 5px;
			position: relative;

			z-index: 2;
			width: 100%;

			@media (min-width:993px) {
				top: -80px;
			}


			&.affix {
				position: sticky;
				top: 135px;
				z-index: 5;
				margin-top: 20px;

				@media (max-width:992px) {
					position: relative !important;
					top: 0;
					z-index: initial;
					margin-bottom: 20px;
				}
			}

			.btn-info {
				background-color: #a5d6d1;

				&:hover {
					background-color: #232323;
					border-color: #1d1d1d;
				}
			}

			.ribbon {
				background: #ffffff;
				height: 20px;
				margin: 0 auto;
				position: absolute;
				width: 20px;
				right: 15px;
				top: 0;
				text-align: center;
				vertical-align: middle;

				&:before,
				&:after {
					content: '';
					position: absolute;
					border-top: 5px solid #ffffff;
					height: 0;
					width: 0;
					top: 100%;
				}

				&:before {
					border-right: 10px solid transparent;
					left: 0px;
				}

				&:after {
					border-left: 10px solid transparent;
					right: 0px;
				}
			}

			@media (max-width:992px) {
				margin: 0;
			}

			.unit-icons {
				margin-top: 20px;

				span {
					background-color: var(--primary);
					border-radius: 50%;
					border: 1px solid var(--white);

					&:nth-of-type(1n+6) {
						display: none;
					}
				}
			}

			.unit-price-area {
				margin-bottom: 30px;

				.ut-crt-price-hint {
					font-size: 14px;
					line-height: 15px !important;
					padding-top: 20px;
				}

				p {
					font-size: 28px;
					margin-bottom: 0;
					color: var(--white);
					line-height: 1.25;

					@media (max-width:992px) {
						font-size: 20px;
					}

					.ut-crt-price {
						display: inline-block;
						margin-top: 0;
						line-height: 1;

						@media (max-width:992px) {
							font-size: 28px;
						}
					}

				}
			}
		}



		.main-col {

			position: relative;
			/*padding-right: 30px;

			@media (max-width:992px) {
				padding-right: 15px;
			} */

			.stars {
				margin-top: 10px;
				margin-right: 0;
				color: var(--secondary);
			}



			.description {
				padding: 15px 0;

				p {
					font-size: 16px;
				}
			}

			.backlink {
				margin-top: 20px;
			}


			.intro {
				i {
					font-size: 26px;
					color: var(--primary2);
				}

				>div {
					text-align: center;
				}
			}

			.properties {
				margin-left: 15px;
				margin-right: 15px;
				@mixin columnlayout 2;

				.prop.bool {
					font-weight: bold;
				}

				.prop.missing {
					text-decoration: line-through;
					font-weight: normal;
				}

				.stars {
					display: inline-block;
					color: #dfb62d;
				}
			}

			.feedbacks {
				margin-top: 30px;

				blockquote {
					div {
						/*font-family: 'Indie Flower', cursive;*/
					}

				}

				.customer-comment {
					background: #f5f5f5;
					padding: 15px;
					margin: 15px 0 0 0;
					position: relative;

					&:before {
						position: absolute;
						display: block;
						width: 0;
						height: 0;
						top: -10px;
						margin-left: 5px;
						content: " ";
						border-color: #f5f5f5 transparent transparent;
						border-style: solid;
						border-width: 10px 10px 0;
						-webkit-transform: rotate(178deg);
						transform: rotate(178deg);
					}
				}


				footer {
					span {
						display: inline-block;
						position: unset;
						font-style: italic;
						font-size: 14px;
					}
				}

				.button-line {
					span {
						font-size: unset;
						color: unset;
						position: relative;
						top: auto;
					}
				}
			}


		}

		.fixnavigationbar {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 50;
			background-color: var(--grey);
			box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .1);

			@media (max-width:992px) {
				display: none !important;
			}

			&.affix-top {
				display: none;
			}

			&.affix {
				display: block;
			}

			.unit-dt-bl {
				margin: 0;

				span {
					font-size: 17px;
					padding: 21px 15px;

					&:first-child {
						border-left: 1px solid #C0C0C0;
					}
				}

				a {
					color: #505050;
				}
			}

			.unit-side-price {
				min-height: auto;
				padding: 13px 20px;
				display: flex;
				justify-content: space-between;

				.btn {
					padding-right: 25px;
					font-size: 15px;
					height: 35px;

					i {
						font-size: 13px;
						top: 10px;
						right: 8px;
					}
				}



				p {
					font-size: 17px;

					.ut-crt-price {
						font-size: 25px;
					}
				}

				span {
					font-size: 10px;
					margin-top: 0;
				}


			}
		}

		.unit-prop-bl {

			position: relative;

			&.booking-bl {
				margin-top: 25px;
			}



			.unit-pro-bl {
				margin-bottom: 40px;
			}
		}

		.show-hide-btn {
			border: 1px solid var(--grey2);
			padding: 6px 20px 4px;
			position: relative;
			border-top: none;
			display: inline-block;
			color: inherit;
			position: absolute;
			bottom: -34px;
			border-radius: 0 0 4px 4px;
			left: 50%;
			transform: translate(-50%, 0);
			background-color: var(--white);

			&:after {
				margin-left: 15px;
				content: "\f078";
				font-family: Font Awesome\ 5 Pro;
				font-weight: 300;
			}
		}



		.legend-cal {
			text-align: left;
			padding: 0;
			margin: 15px 0;

			@media (min-width:768px) {
				display: flex;
			}

			>span {
				margin-top: 8px;
				margin-right: 10px;
			}

			ul {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
			}

			li {
				display: inline-flex;
				align-items: center;
				min-height: 30px;
				margin: 5px 0;
				padding-left: 25px;
				position: relative;
				padding-left: 20px;
				padding-right: 20px;

				@media (min-width:993px) {
					padding-left: 40px;
				}

				span {
					text-align: left;

					@media (min-width:993px) {
						margin-top: 6px;
					}
				}

				.day {
					width: 30px;
					height: 30px;
					background-color: #7e7975;
					display: inline-block;
					margin: 0 10px;
					border-radius: 5px;
					position: absolute;
					left: 0;
					top: 0px;
					margin: 0;

					@media (max-width:992px) {
						width: 15px;
						height: 15px;
						top: 5px;
					}

					&.day-free {
						background: #899a4c;
					}

					&.day-full {
						background: #EA655B;
					}

					&.day-end {
						background: rgb(163, 101, 123);
						background: linear-gradient(135deg, rgba(234, 101, 91, 1) 50%, rgba(137, 154, 76, 1) 50%);
					}

					&.day-start {
						background: rgb(243, 243, 243);
						background: linear-gradient(135deg, rgba(137, 154, 76, 1) 50%, rgba(234, 101, 91, 1) 50%);
					}

					&.day-disable {
						background-color: rgba(137, 154, 76, .65) !important;
					}
				}
			}
		}

		.tick-li {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 20px;
			list-style: none;

			li {
				width: 33%;
				padding-left: 30px;
				padding-right: 15px;
				min-height: 30px;
				position: relative;

				@media (max-width:767px) {
					width: 48%;
				}

				@media (max-width:480px) {
					width: 100%;
				}

				&:before {
					content: "\f14a";
					font-family: Font Awesome\ 5 pro;
					-webkit-font-smoothing: antialiased;
					font-weight: 400;
					display: inline-block;
					position: absolute;
					left: 0;
					top: 0;
					color: var(--primary);
				}
			}
		}

		@media (min-width:768px) {
			.info-col.noaction {
				position: absolute;
				right: 0;
				top: -41px;

				width: 33.33333333%;
				/*
				padding: 3px;
				padding-top: 10px;
				position: -webkit-sticky;
				position: sticky;
				top: 10px;
           */

				.info-col-header {
					height: 40px;
					padding: 1px;
					background-color: var(--primary);
					color: var(--white);
					text-align: center;

					h2 {
						margin-top: 10px;
						font-size: 18px;
					}
				}

				.info-col-main {
					border: 1px solid #888;
					border-bottom: none;
					width: 100%;
				}

				.subprice {
					width: 100%;
					border: 1px solid #888;
					border-top: none;
				}

				.fav-section {
					margin-top: 20px;
					border-bottom: 1px solid #888 !important;
					padding: 15px;
				}

			}
		}

		.unit-act-side {

			background-color: var(--grey);
			margin: 0 -15px 20px;
			padding: 15px 20px;

			@media (max-width:992px) {
				margin: 0 0 20px;
			}

			.btn+.btn {
				margin-top: 15px;
			}

			.btn {
				font-size: 20px;
				padding: 7px 15px;
				position: relative;


			}

		}

		.fav-section {
			background-color: var(--grey);
			margin: 0 -15px 20px;
			padding: 15px;

			@media (max-width:992px) {
				margin: 0 0 20px;
			}

			.serv-title {
				margin-bottom: 15px;
				padding-bottom: 6px;
				border-bottom: 1px solid var(--primary);
				font-size: 16px;
				color: var(--primary);
				font-family: var(--ft-futura-b);
			}

			.unit-fav-ct {
				display: flex;
				justify-content: space-between;
				margin-bottom: 20px;
				font-size: 15px;

				span {
					display: block;
				}



				.unit-img-bl {
					width: 50%;
				}
			}

		}

		.info-col {

			max-width: 800px;

			.info-col-main {

				padding: 15px 15px 1px 15px;
			}

			.subprice {

				padding: 1px 15px 15px 15px;

				.filters {}


				.book-btn {
					margin-top: 30px;
					margin-bottom: 10px;
				}

				.flow-buttons {
					button {}
				}
			}




		}

		.price-list {
			max-width: 400px;

			.subline {
				.labelcol {
					padding-left: 20px;
				}

				td {
					border-top: none;
				}
			}

		}
	}


	.subsection {
		width: 100%;

		.unit-map {
			height: 350px;
			width: 100%;

			>div {
				height: 100%;
				padding: 0 0 60px 0;
			}

			&.no-map {
				>div {
					padding: 0 0 0px 0;
				}

				.mapconsent {
					height: 100%;
					background: rgba(26, 121, 187, .15);
					display: flex;
					align-items: center;
					justify-content: center;
					flex-wrap: wrap;
					flex-direction: column;
					text-align: center;
					padding: 0 15px;

					p {
						margin-bottom: 15px;
					}

					a {
						background: var(--primary);
						color: #fff;
						border-radius: 5px;
						padding: 2px 15px;
						height: 36px;
						display: block;
						line-height: 35px;
					}
				}
			}

			.mapnote {
				font-size: 16px;
				margin: 0 0 15px 0;

				@media (max-width: 767px) {
					font-size: 12px;
					min-height: 52px;
				}
			}
		}
	}


	.matterport-showcase {
		text-align: center;

		iframe {
			min-height: 240px;
			margin: 16px 0;
			width: 100%;
		}

	}

	.subsection {
		position: relative;

		.map-center-bl {
			@media (min-width:993px) {
				position: absolute;
				margin: 0 auto;
				left: 50%;
				top: 50px;
				transform: translate(-50%, 0);
			}
		}

		.map-cont-bl {
			padding: 15px;
			display: inline-block;
			max-width: 360px;

			width: 100%;
			background-color: rgba(255, 255, 255, .85);

			@media (min-width:993px) {
				padding: 30px;
				left: 0;
				top: 50px;

				z-index: 999;
			}

			strong {
				margin-bottom: 5px;
				display: block;
			}

			ul {
				li {
					width: 100%;
					display: flex;
					justify-content: space-between;
					list-style: none;
					border-bottom: 1px solid var(--grey2);

					&:last-child {
						border-bottom: none;
					}

					div {
						display: inline-block;
						text-align: right;
					}
				}
			}
		}
	}

	.datepicker-trigger {
		display: inline-block;

		.asd__day--arrival {
			background: #899a4c;
			background: linear-gradient(135deg, #899a4c 50%, #ea655b 0) !important;
			opacity: 1 !important;
		}

		.asd__day--departure {
			background: #ea655b;
			background: linear-gradient(135deg, #ea655b 50%, #899a4c 0) !important;
			opacity: 1 !important;
		}

		.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
			background: #899a4c;
			background: linear-gradient(135deg, #899a4c 50%, #ea655b 0) !important;
		}

		.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
		.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
			color: var(--white) !important;
		}

		.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
			background: #ea655b;
			background: linear-gradient(135deg, #ea655b 50%, #899a4c 0) !important;
		}

		.asd__day-button {
			font-weight: bold !important;
		}

		.asd__day--enabled {
			background: #899a4c;
			cursor: pointer;
			color: #fff !important;
			border: 1px solid #e4e7e7 !Important;

			&.asd__day--disabled {
				color: var(--white) !important;
				background-color: rgb(137, 154, 76, .65) !important;
				opacity: .8;
			}

			&.asd__day--hovered {
				background: #f9dd5c !important;
			}

			&.asd__selected-date-one {
				background: #92802b !important;
				background-image: url(RESOURCE/img/date-start.png) !important;
				background-repeat: no-repeat !important;
				background-size: 15px !important;
				background-position: 100% !important;
				opacity: 1 !important;
			}


			&.asd__day--not-available {
				background: var(--not-available) !important;
				opacity: 1 !important;
				cursor: not-allowed;
				color: #fff !important;
			}

			&:hover {
				background-color: #899a4c;
			}
		}


		.asd__day--in-range {
			background: #f9dd5c !important;
		}

		.asd__day--selected {
			border-radius: 0 !important;

			&.asd__selected-date-two {
				background: #92802b !important;
				background-image: url(RESOURCE/img/date-end.png) !important;
				background-repeat: no-repeat !important;
				background-size: 15px !important;
				background-position: 0 !important;
				opacity: 1 !important;
			}
		}
	}

	.unitGallery {

		.imgcols {
			width: 33.33%;
			position: relative;
			overflow: hidden;
			margin-bottom: 20px;
			float: left;
			padding: 0 10px;
			height: 262px;

			&:before {
				content: "";
				background-color: rgba(128, 112, 112, 0);
				position: absolute;
				left: 0;
				top: 0;
				opacity: 0;
				visibility: hidden;
				transition: all .5s ease-in-out;
				opacity: 1;
				width: calc(100% - 20px);
				height: 100%;
				left: 10px;
			}

			&:hover {
				&:before {
					opacity: 1;
					visibility: visible;
					background-color: rgba(128, 112, 112, .5);
				}
			}

			@media (max-width:640px) {
				width: 50% !important;
				height: 110px !important;
			}

			&:first-child,
			&:nth-child(11),
			&:nth-child(21),
			&:nth-child(31),
			&:nth-child(41),
			&:nth-child(51) {
				width: 66.66%;
				float: left;
				height: 262px;


				img {
					width: 100%;
					height: 100%;
				}
			}


			&:nth-child(2),
			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6),
			&:nth-child(8),
			&:nth-child(9),
			&:nth-child(10),
			&:nth-child(12),
			&:nth-child(13),
			&:nth-child(14),
			&:nth-child(15),
			&:nth-child(16),
			&:nth-child(18),
			&:nth-child(19),
			&:nth-child(20),
			&:nth-child(22),
			&:nth-child(23),
			&:nth-child(24),
			&:nth-child(25),
			&:nth-child(26),
			&:nth-child(28),
			&:nth-child(29),
			&:nth-child(30) {
				width: 33.33%;
			}

			&:nth-child(7),
			&:nth-child(16),
			&:nth-child(27),
			&:nth-child(37),
			&:nth-child(47) {
				width: 66.66%;
				float: right;
				height: 262px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			@media (max-width:640px) {
				width: 100%;
			}


			img {
				-o-object-fit: cover;
				object-fit: cover;
				-o-object-position: center center;
				object-position: center center;
				height: 100%;
				width: 100%;

			}
		}
	}

	.unit-tbl-row {
		td {
			border-top: 1px solid var(--grey2);
			padding: 15px 0;

			&:first-child {
				font-family: var(--dfont2);
				width: 25%;
			}
		}
	}


	@media all and (max-width:992px) {

		.title-img {
			height: 350px;

			.v-left-gallery-btn,
			.v-right-gallery-btn {
				font-size: 80px;
			}

		}

		.panorama {
			height: 350px;
		}


		.page-header {
			.unit-title-bx {


				h1 {
					font-size: 20px;
					padding-right: 15px;

					small {
						display: none;
					}
				}
			}


		}

		.main-section {

			.main-col {
				.properties {
					@mixin columnlayout 1;
				}
			}
		}

	}

	.feedback-section {
		font-size: 16px;

		.feed-rat-blok {
			background: var(--primary);
			color: var(--white);
			border-radius: 4px;
			text-align: center;
			padding: 15px;
			margin-bottom: 15px;

			.vote-total {
				font-size: 40px;
			}

			span {
				font-size: 14px;
			}


		}

		.feedbk-rat-right {
			display: flex;
			align-items: center;
			font-size: 16px;
			padding: 4px 0;

			.fd-title {
				width: 150px;
			}

			.ratings {
				margin-right: 60px;

				.stars {
					.fa {
						width: 16px;
						height: 16px;
						border-radius: 50%;
						display: inline-block;
						background-color: var(--primary);

						&:before {
							content: "";
						}
					}
				}


			}

		}

		.feed-commet-bl {
			/* display: flex; */
			margin-bottom: 1rem;
			border: 1px solid;

			padding: 0.5rem;

			p {
				font-style: italic;
				color: var(--black2);
			}
		}

		.tx-thrating-ratings-other {
			padding: 1rem 0;
			margin-bottom: 15px;
		}

		.tx-thrating-ratings-other span {
			background: #9d9d9c;
			color: var(--white);
			padding: 0.2rem 1rem;
			border-radius: 4px;
			display: inline-block;
		}

		.tx-thrating-ratingdate {
			float: right;
		}
	}






	@media (min-width:768px) and (max-width:992px) {

		.title-img {
			height: 465px;
		}

		.panorama {
			height: 465px;
		}

		.matterport-showcase iframe {
			min-height: 360px;
		}


	}

	@media (min-width:993px) and (max-width:1199px) {

		.title-img {
			height: 580px;
		}

		.panorama {
			height: 580px;
		}

		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}


	}


	@media screen and (min-width: 993px) {
		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}

	}


	.ratings {
		.ptitle {
			display: inline-block;
			min-width: 120px;
		}

		.stars {
			.fa {
				width: 16px;
				height: 16px;
				border-radius: 50%;
				display: inline-block;
				border: 1px solid var(--primary);

				&.fa-star {
					background-color: var(--primary);
					border: 1px solid var(--primary);
				}

				&:before {
					content: "";
				}

				&.fa-star-half {
					background: rgb(97, 132, 122);
					background: linear-gradient(90deg, rgba(97, 132, 122, 1) 50%, rgba(255, 255, 255, 1) 50%);
				}

				&.fa-star-half-alt {
					background: rgb(97, 132, 122);
					background: linear-gradient(90deg, rgba(97, 132, 122, 1) 50%, rgba(255, 255, 255, 1) 50%);
				}

				&.fa-star-o {
					background-color: transparent !important;
				}
			}
		}

		span {
			font-size: 16px;
			color: var(--green);
			/*display: block;*/
			position: relative;
			top: -4px;
		}
	}


}

.cal-haedline {
	display: flex;
	flex-wrap: wrap;
	padding-top: 45px;
	position: relative;

	h2 {
		margin-top: 0 !important;
		margin-right: 15px;
	}
}

.btn-bl-reset {
	margin: 0 0 10px;

	.reset-btn2 {
		height: 30px;
		border: 1px solid #7b7b7b;
		background-color: #fff;
		border-radius: 5px;
		color: #7b7b7b;
		font-size: 14px;

		&:hover {
			color: #fff;
			background-color: #0e71b8;
			border: 1px solid #fff;
		}
	}
}